import React, { Component } from 'react'
import Home from './pages/Landing page/Home'


export default class App extends Component {
  render() {
    return (
      <div>
       <Home/>
      </div>
    )
  }
}

