import React, { Component } from 'react';
import './header.css';

class Header extends Component {

   handleInquireClick = () => {
    // Scroll to the bottom of the page
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: 'smooth'
    });
  };

  
  render() {
    return (
    <div style={{ position: "relative", height: "810px" }}>
      <div style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%", backgroundImage: "url('/images/bg.png')", rotate:"-0 deg", backgroundRepeat:"no-repeat", backgroundSize:"cover" }}></div>
      <header style={{ position: "relative" }}>
        {/* Text */}
        <nav className="navbar navbar-expand-lg bg-dark">
          <div className="container-fluid">
            <h6 className="navbar-text fonix" id="navbar-text">
              <span>
                "Award-Winning Innovation at Your Fingertips: Fonix Software Solutions Pvt Ltd - Empowering Digital Transformation, Strategic Business Growth, and Cutting-Edge Creativity for Tomorrow's Success."
              </span>
            </h6>
          </div>
        </nav>

        {/* Logo */}
        <img
          src="/images/Fonix logo.png"
          alt="Logo"
          className="img-fluid"
          id="fonixlogo"
          
        />

        {/* Content */}
        <div className="row">
          <div className="col-md-6">
            <div className="p-3 p-md-5 text-center bg-image" style={{ marginTop: "30px" }}>
              <div className="mask">
                <div className="d-flex justify-content-center align-items-center h-100">
                  <div className="text-white">

                  
                    <div className='row'>
                      <div className='col'>
                        <h4 id='welcome'>Welcome to</h4>
                      </div>
                    </div>

                    <div className="row" id="headerRow" >
                      <div className='col'>
                        <h1 id="fonix">FONIX</h1>
                      </div>

                      <div className='col'>
                        <h1 id="software" >Software</h1>
                      </div>
                    </div>

                    <div className='col'>
                      <h1 className="mb-3" id="soluation">Solutions</h1>
                    </div>

                    <div className='col'>
                      <h1 className="mb-3" id="pvtLtd" >Pvt Ltd.</h1>
                    </div>

                    <div className='col'>
                      <a
                        data-mdb-ripple-init
                        className="btn btn-outline-light btn-lg"
                        href="#1"
                        role="button"
                        id="contactBtn"
                        onClick={this.handleInquireClick} // Referencing the method from the class
                      >
                        Contact Us&nbsp;<i className="fas fa-headphones"></i>
                      </a>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-6">
            {/* Company Image */}
            <img
              src="/images/fonix.png"
              alt="Logo"
              className="img-fluid company-image"
              style={{float:"right",  width:"auto", height:"auto" }}
            />
          </div>
        </div>
      </header>
    </div>
    );
  }
}

export default Header;
