import React, { Component } from 'react'
import "./product.css"

export default class Product extends Component {
  render() {
    return (
      <div className='container'>
         <center>
    <h4 className="mt-5" id="productTitle" ><b>Award wining products</b></h4>
    </center>
    <div className='d-flex flex-wrap justify-content-center mt-3'>
   
    <div className='container mb-4' id="border"  >
    <h4 className="mt-4" id="productName"><b>Exam Hub</b></h4>
    <img className="profile-img img-fluid" id="examHubImg" src="/images/examhub.png" alt="Exam Hub" />
    <a href="https://examhub.lk" id="learnMore1" target='_black' class="btn btn-danger" >Learn more&nbsp;<i class="fas fa-arrow-right"></i></a>
  
</div>

<div className='container mb-4' id="border" >
<h4 className="mt-4" id="productName" ><b>Coming soon!</b></h4>
<img className="profile-img img-fluid" id="fedesImg" src="/images/fedes.png" alt="Fedes" />
<a href="1" class="btn btn-danger" id="learnMore2" >Learn more&nbsp;<i class="fas fa-arrow-right"></i></a>

</div>
    </div>
      </div>
      
    )
  }
}
