import React from 'react';
import './card.css';


export default function Service() {
  

  const handleInquireClick = () => {
    // Scroll to the bottom of the page
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: 'smooth'
    });
  };

  return (
    <div className='container'>
      <div className='container-fluid mx-auto'>
        <h2 className='mb-3' id="ourService">Our Service</h2>

        <div className='row'>
          <div className='col-md-4'>
            {/* Digital Marketing */}
            <div className='card animated-card' style={{ margin: '0.5rem' }}>
              <div className='card-body d-flex flex-column'>
                <h5 className="card-title" id="cardTitle" >
                  <b><img
                    src="/images/service/digital.png"
                    alt="Logo"
                    id="serviceImg"
                    className="img-fluid"
                  />&nbsp;&nbsp;&nbsp;Digital Marketing</b>
                </h5>
                <p className="card-text" id="cardText" >
                  Elevate your brand with our cutting-edge digital marketing strategies, including social media, and PPC campaigns, to boost online presence.
                </p>
                <div className="mt-auto">
                  <button onClick={handleInquireClick} className="btn btn-danger" id="InquireBtn">
                    Inquire&nbsp;<i className="fas fa-arrow-right"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className='col-md-4'>
            {/* Business Consulting */}
            <div className='card animated-card' style={{ margin: '0.5rem' }}>
              <div className='card-body d-flex flex-column'>
                <h5 className="card-title" id="cardTitle">
                  <b><img
                    src="/images/service/business.png"
                    alt="Logo"
                    id="serviceImg"
                    className="img-fluid"
                  />&nbsp;&nbsp;&nbsp;Business Consulting</b>
                </h5>
                <p className="card-text" id="cardText" >
                  Transform your business with our expert consulting services, offering personalized strategies for growth, efficiency, and competitive advantage.
                </p>
                <div className="mt-auto">
                  <button onClick={handleInquireClick} className="btn btn-danger" id="InquireBtn" >
                    Inquire&nbsp;<i className="fas fa-arrow-right"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className='col-md-4'>
            {/* R&D */}
            <div className='card animated-card' style={{ margin: '0.5rem' }}>
              <div className='card-body d-flex flex-column'>
                <h5 className="card-title" id="cardTitle">
                  <b><img
                    src="/images/service/r&d.png"
                    alt="Logo"
                    id="serviceImg"
                    className="img-fluid"
                  />&nbsp;&nbsp;&nbsp;R&D</b>
                </h5>
                <p className="card-text" id="cardText" >
                  Drive innovation with our Research and Development services, focusing on technological advancements and custom solutions to stay ahead in your industry.
                </p>
                <div className="mt-auto">
                  <button onClick={handleInquireClick} className="btn btn-danger" id="InquireBtn" >
                    Inquire&nbsp;<i className="fas fa-arrow-right"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        

      </div>
    </div>
  );
}
